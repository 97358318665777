import { ReactElement } from 'react';
import { Heading, List, ListItem, Paragraph } from '@mediahuis/chameleon-react';

import { HTMLTextNode, KeyValue, RichTextFieldFragment } from '@hubcms/domain-cook';

import styles from './styles.module.scss';

type RichTextElementProps = {
  data: RichTextFieldFragment | RichTextFieldFragment['children'][number];
};

type RichTextFieldProps = {
  data: RichTextFieldFragment[] | RichTextFieldFragment['children'];
};

const Annotations = {
  b: 'strong',
  i: 'em',
  u: 'u',
  sub: 'sub',
  sup: 'sup',
  strike: 'strike',
  ul: List,
  ol: List,
  li: ListItem,
};
const Headings = {
  h1: '1',
  h2: '2',
  h3: '3',
  h4: '4',
  h5: '5',
  h6: '6',
};

type RichTextElementWithChildren = {
  type: string;
  children: RichTextFieldFragment['children'];
  attributes: KeyValue[];
};

const hasChildren = (element: RichTextElementProps['data']): element is RichTextElementWithChildren => {
  return !!element && !!(element as RichTextFieldFragment).children;
};

function RichTextFieldElement({ data }: RichTextElementProps): ReactElement {
  const AnnotationNode = Annotations[data.type] || '';
  const HeadingLevel = Headings[data.type] || '';
  return (
    <>
      {data.type === 'p' && hasChildren(data) && (
        <Paragraph className={styles.rtParagraph}>
          <RichTextField data={data.children} />
        </Paragraph>
      )}
      {data.type === 'text' && (data as HTMLTextNode).text}
      {HeadingLevel && hasChildren(data) && (
        <Heading className={styles.rtHeading} level={HeadingLevel}>
          <RichTextField data={data.children} />
        </Heading>
      )}
      {AnnotationNode && hasChildren(data) && (
        <AnnotationNode>
          <RichTextField data={data.children as RichTextFieldFragment[]} />
        </AnnotationNode>
      )}
    </>
  );
}

export default function RichTextField({ data }: RichTextFieldProps) {
  const hasElements = data.length >= 1;

  if (!hasElements) {
    return null;
  }

  return (
    <>
      {data.map((element, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <RichTextFieldElement data={element} key={`rte-${index}`} />;
      })}
    </>
  );
}
