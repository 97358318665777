import { useMemo } from 'react';
import { Heading } from '@mediahuis/chameleon-react';
import { Ad } from '@hubcms/feature-ads';
import { StoryblockList, useFlattenTeaserLists } from '@hubcms/feature-storyblock';
import { Container } from '@hubcms/ui-container';
import { useAdPositions } from '@hubcms/utils-ads';
import { usePager } from '@hubcms/utils-pagination';
import { MhluSectionTemplateData, SectionData } from '@hubcms/domain-section';
import { TeaserPropsOptions, isArticleTeaserData } from '@hubcms/domain-teaser';
import { deduplicateObjectsByKey, partitionArray } from '@hubcms/utils-browser';

import { InfiniteScrollArticleList } from '../../InfiniteScrollArticleList';
import MHLUSectionOpening from '../../MHLUSectionOpening';

import styles from './section.module.scss';

type MHLUSectionProps = {
  sectionData: SectionData;
  templateData: MhluSectionTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

export default function MHLUSection({ sectionData, templateData, teaserPropsOptions }: MHLUSectionProps) {
  const { pagedArticlesData } = sectionData;
  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  const news = useFlattenTeaserLists({
    news: templateData.deskedTeaserData,
  }).news.filter(isArticleTeaserData);

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey([...news, ...articles], 'id'), [news, articles]);

  // Slice some articles for the opening block
  const [templateArticles, infiniteScrollArticles] = useMemo(() => {
    return partitionArray(deduplicatedArticles, (_, idx) => idx < 3);
  }, [deduplicatedArticles]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);

  if (sectionData.noContentMessage) {
    return (
      <Container>
        <Heading size="xl" level={4}>
          {sectionData.noContentMessage}
        </Heading>
      </Container>
    );
  }

  return (
    <>
      <StoryblockList storyblocks={sectionData.storyblocks} teaserPropsOptions={teaserPropsOptions} />
      <div className={styles.mySectionContainer}>
        <Container fullWidthSm fullWidthMd>
          <MHLUSectionOpening templateData={templateData} articles={templateArticles} />
        </Container>
        {sectionData.templateDividerAdFormat && <Ad adFormat={sectionData.templateDividerAdFormat} adSlot="b" />}
        <Container fullWidthSm fullWidthMd>
          <InfiniteScrollArticleList
            teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
            articles={infiniteScrollArticles}
            pageSize={pagedArticlesData.pageSize}
            initialPageSize={pagedArticlesData.initialAmount}
            isLoading={isLoading}
            hasMore={hasMore}
            getAdFormatForPage={getAdFormatForPage}
            loadMore={loadPage}
            asideRecirculationStoryblocks={pagedArticlesData.asideRecirculationStoryblocks}
            teaserPropsOptions={teaserPropsOptions}
          />
        </Container>
      </div>
    </>
  );
}
