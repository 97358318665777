import { AuthorProfileData } from '@hubcms/domain-cook';
import { createAuthorProfileTemplateData } from '@hubcms/domain-section';

export function extractAuthorProfileFields(authorProfileContextData: AuthorProfileData['context']) {
  const {
    name,
    bio,
    email,
    twitterHandle,
    facebookProfile,
    instagramProfile,
    phoneNumber,
    function: authorFunction,
  } = authorProfileContextData?.fields || {};
  return createAuthorProfileTemplateData({
    name: name || '',
    authorFunction: authorFunction || '',
    bio: bio || undefined,
    email: email || undefined,
    twitterHandle: twitterHandle || undefined,
    facebookProfile: facebookProfile || undefined,
    instagramProfile: instagramProfile || undefined,
    phoneNumber: phoneNumber || undefined,
    headShotImageUrl: authorProfileContextData?.headshotImage?.[0]?.content?.fields.oneOne?.href_full,
  });
}
