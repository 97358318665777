import { RichTextFieldFragment } from '@hubcms/domain-cook';

export type AuthorProfileTemplateData = {
  template: 'author';
  name: string;
  authorFunction: string;
  bio?: RichTextFieldFragment[]; // TODO: CW-2008 - Separate data type
  email?: string;
  twitterHandle?: string;
  facebookProfile?: string;
  instagramProfile?: string;
  phoneNumber?: string;
  headShotImageUrl?: string;
};

export function createAuthorProfileTemplateData({
  name = '',
  authorFunction = '',
  ...optionalProperties
}: Partial<Omit<AuthorProfileTemplateData, 'template'>> = {}): AuthorProfileTemplateData {
  return {
    template: 'author',
    name,
    authorFunction,
    ...optionalProperties,
  };
}
